import { Select, FormHelperText, FormControl, InputLabel, MenuItem } from "@mui/material";
import {
  Criterium,
  EvaluableType,
  Evaluation,
  Subcriterium,
} from "../../types";
import React from "react";
import { isEmpty } from "lodash";
import { useEvaluationContext } from '../../contexts/Evaluation.context'

export default function EvaluationComponent({
  criterium,
  evaluation,
  evaluableId,
  evaluableType,
}: {
  criterium: Criterium;
  evaluation?: Evaluation;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const { getSelectedSubcriterium, handleEvaluationChange } = useEvaluationContext()

  if(getSelectedSubcriterium === undefined || handleEvaluationChange === undefined){
    return <></>
  }

  const value = getSelectedSubcriterium({ criteriumId: criterium.id })?.id || ""

  return (
    <FormControl fullWidth>
      <InputLabel id="select-evaluation-label" htmlFor="select-evaluation">{criterium.name}</InputLabel>
      <Select
        fullWidth
        labelId="select-evaluation-label"
        id="select-evaluation"
        label={criterium.name}
        // disabled={criterium.numericalAutoSelect}
        inputProps={{ readOnly: criterium.numericalAutoSelect }}
        value={value}
        onChange={handleEvaluationChange && handleEvaluationChange({ criterium, evaluation, evaluableId, evaluableType })}
      >
        <MenuItem value={""}>
          <em>None</em>
        </MenuItem>
        {criterium &&
          !isEmpty(criterium.subcriteria) &&
          criterium.subcriteria
            .sort((a, b) => a && b && a.location - b.location)
            .map((subcriterium: Subcriterium) => (
              <MenuItem value={subcriterium.id} key={subcriterium.id}>
                {subcriterium.name}
              </MenuItem>
            ))}
      </Select>
      {criterium.numericalAutoSelect && <FormHelperText>Auto-selected by measurement</FormHelperText>}
    </FormControl>
  );
}
